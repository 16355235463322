import { inject, Injectable, isDevMode } from '@angular/core';
import { UserStore } from '../store/user/user.store';
import { EquipmentArrowsStore } from '../store/equipment/equipment-arrows.store';
import { EquipmentBowsStore } from '../store/equipment/equipment-bows.store';
import { AdminCompetitionsStore } from '../store/admin/admin-competitions.store';
import { AdminOrganizationStore } from '../store/admin/admin-organizations.store';
import { SuperAdminUsersStore } from '../store/superadmin/superadmin-user.store';
import { SuperAdminOrganizationsStore } from '../store/superadmin/superadmin-organizations.store';
import { AdminScoringStore } from '../store/admin/admin-scoring.store';
import { ICompetition } from '@archery-scoring/models/competition.model';

@Injectable({
  providedIn: 'root',
})
export class PrefetchService {
  private readonly userStore: InstanceType<typeof UserStore> = inject(UserStore);
  private readonly equipmentArrowsStore: InstanceType<typeof EquipmentArrowsStore> = inject(EquipmentArrowsStore);
  private readonly equipmentBowsStore: InstanceType<typeof EquipmentBowsStore> = inject(EquipmentBowsStore);
  private readonly adminCompetitionsStore: InstanceType<typeof AdminCompetitionsStore> = inject(AdminCompetitionsStore);
  private readonly adminOrganizationStore: InstanceType<typeof AdminOrganizationStore> = inject(AdminOrganizationStore);
  private readonly adminScoringStore: InstanceType<typeof AdminScoringStore> = inject(AdminScoringStore);
  private readonly superAdminUsersStore: InstanceType<typeof SuperAdminUsersStore> = inject(SuperAdminUsersStore);
  private readonly superAdminOrganizationsStore: InstanceType<typeof SuperAdminOrganizationsStore> =
    inject(SuperAdminOrganizationsStore);

  public prefetchArrows(): void {
    if (!this.userStore.authenticated()) {
      return;
    }

    if (!this.equipmentArrowsStore.arrowsLoaded()) {
      this.equipmentArrowsStore.getArrows();

      if (isDevMode()) {
        console.log('%c 🗃️ Prefetching user arrows', 'background: #fff; color: #607D8B;');
      }
    }
  }

  public prefetchBows(): void {
    if (!this.userStore.authenticated()) {
      return;
    }

    if (!this.equipmentBowsStore.bowsLoaded()) {
      this.equipmentBowsStore.getBows();

      if (isDevMode()) {
        console.log('%c 🗃️ Prefetching user bows', 'background: #fff; color: #607D8B;');
      }
    }
  }

  public prefetchTrainingHistory(): void {
    if (!this.userStore.authenticated()) {
      return;
    }

    // if (!this.equipmentBowsStore.bowsLoaded()) {
    //   this.equipmentBowsStore.getBows();
    //
    //   if (isDevMode()) {
    //     console.log('%c 🗃️ Prefetching user bows', 'background: #fff; color: #607D8B;');
    //   }
    // }
  }

  public prefetchAdmin(): void {
    if (!this.userStore.isAdmin()) {
      return;
    }

    if (!this.adminOrganizationStore.organizationsLoaded()) {
      this.adminOrganizationStore.getOrganizations();

      if (isDevMode()) {
        console.log('%c 🗃️ Prefetching admin organizations', 'background: #fff; color: #607D8B;');
      }
    }

    if (!this.adminCompetitionsStore.competitionsLoaded()) {
      this.adminCompetitionsStore.getCompetitions();

      if (isDevMode()) {
        console.log('%c 🗃️ Prefetching admin competitions', 'background: #fff; color: #607D8B;');
      }
    }
  }

  public prefetchSuperAdmin(): void {
    if (!this.userStore.isSuperAdmin()) {
      return;
    }

    if (!this.superAdminOrganizationsStore.organizationsLoaded()) {
      this.superAdminOrganizationsStore.getOrganizations();

      if (isDevMode()) {
        console.log('%c 🗃️ Prefetching super admin organizations', 'background: #fff; color: #607D8B;');
      }
    }

    if (!this.superAdminUsersStore.usersLoaded()) {
      this.superAdminUsersStore.getUsers();

      if (isDevMode()) {
        console.log('%c 🗃️ Prefetching super admin users', 'background: #fff; color: #607D8B;');
      }
    }
  }

  public prefetchAdminScores(competition: ICompetition): void {
    if (!this.userStore.isAdmin() || this.adminScoringStore.getCompetitionId() === competition.id) {
      return;
    }

    this.adminScoringStore.setCompetitionId(Number(competition.id));
    this.adminScoringStore.getCompetitionScores({ gender: competition.gender, agebrackets: competition.agebrackets });
  }
}
