import { patchState, signalStore, type, withHooks, withMethods } from '@ngrx/signals';
import { setEntity, withEntities } from '@ngrx/signals/entities';
import { SuperAdminUserService } from '../../services/http/superadmin-user.service';
import type { IUser, IUserAssign } from '@archery-scoring/models/user.model';
import { ApplicationRef, effect, inject, isDevMode, PendingTasks, PLATFORM_ID, untracked } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { pipe, switchMap } from 'rxjs';
import { tapResponse } from '@ngrx/operators';
import { UserStore } from '../user/user.store';
import { isPlatformBrowser } from '@angular/common';
import { withCallState } from '@owain/store-features/features/call-state/call-state.feature';
import { withDataService } from '@owain/store-features/features/data-service/data-service-feature';
import { ToasterService } from '@owain/notifier/lib/services/toaster.service';
import { EventBusService } from '@owain/eventbus/event-bus.service';
import { NotificationType } from '@owain/notifier/lib/models/notifications.model';
import { withSideEffects } from '@owain/store-features/features/side-effects/side-effects.feature';
import { withEntitiesLocalPagination } from '@owain/store-features/features/entities/pagination/entities-local-pagination';
import { withEntitiesFilter } from '@owain/store-features/features/entities/filter/entites-filter.feature';
import { withDevtools } from '@owain/store-features/features/debugging/devtools';
import { WINDOW } from '@owain/tokens/window.provider';
import { filter, takeWhile, tap } from 'rxjs/operators';
import { immerPatchState } from '@owain/store-features/state/immer.state';

export const SuperAdminUsersStore = signalStore(
  { providedIn: 'root' },
  withDevtools('Archery Scoring', 'Super Admin Users'),
  withCallState({
    collection: 'user',
  }),
  withEntities({
    entity: type<IUser>(),
    collection: 'user',
  }),
  withEntitiesFilter({
    entity: type<IUser>(),
    collection: 'user',
    defaultFilter: { search: '' },
    filterFn: (entity, filter) =>
      !filter?.search ||
      !!(entity?.email && entity?.email.toLowerCase().includes(filter?.search.toLowerCase())) ||
      !!(entity?.name && entity?.name.toLowerCase().includes(filter?.search.toLowerCase())) ||
      !!(entity?.role && entity?.role.toLowerCase().includes(filter?.search.toLowerCase())),
  }),
  withEntitiesLocalPagination({
    entity: type<IUser>(),
    collection: 'user',
    pageSize: 10,
  }),
  withDataService({
    loggerKey: 'SuperAdminUsersStore',
    key: 'superadmin-users',
    dataServiceType: SuperAdminUserService,
    collection: 'user',
  }),
  withMethods(store => {
    const superAdminUserService: SuperAdminUserService = inject(SuperAdminUserService);
    const toastService: ToasterService = inject(ToasterService);
    const translocoService: TranslocoService = inject(TranslocoService);
    const eventBusService: EventBusService = inject(EventBusService);
    const pendingTasks: PendingTasks = inject(PendingTasks);

    return {
      userAssignOrganizations: rxMethod<IUserAssign>(
        pipe(
          switchMap((userAssign: IUserAssign) => {
            immerPatchState(store, state => {
              state.usersCallState = 'loading';
            });

            const taskCleanup = pendingTasks.add();

            return superAdminUserService.userAssignOrganizations(userAssign.id, userAssign.connect).pipe(
              tapResponse({
                next: (user: IUser): void => {
                  const items = store.userEntities();

                  if (!items) {
                    return;
                  }

                  patchState(store, setEntity(user, { collection: 'user' }));

                  toastService.notification({
                    alertType: NotificationType.SUCCESS,
                    message: translocoService.translate('superadmin.assignorgsuccess'),
                  });
                  eventBusService.cast('ui:close-modal');
                },
                error: err => {
                  if (isDevMode()) {
                    console.error('[SuperAdminStore]', err);
                  }

                  toastService.notification({
                    alertType: NotificationType.ERROR,
                    message: translocoService.translate('500error'),
                  });
                },
                finalize: () => {
                  immerPatchState(store, state => {
                    state.usersCallState = 'loaded';
                  });
                  taskCleanup();
                },
              })
            );
          })
        )
      ),
    };
  }),
  withSideEffects({
    key: 'superadmin-users',
    delete: {
      success: {
        type: NotificationType.SUCCESS,
        message: 'superadmin.userdeleted',

        castEvent: 'ui:close-modal',
      },
      failure: {
        type: NotificationType.ERROR,
        message: '500error',
      },
    },
  }),
  withHooks({
    onInit(store) {
      const platformId: Object = inject(PLATFORM_ID);
      const ngWindow: Window = inject(WINDOW);
      const applicationRef: ApplicationRef = inject(ApplicationRef);
      const userStore: InstanceType<typeof UserStore> = inject(UserStore);

      const idleCallbackSupportedW = (window: Window | null): boolean =>
        typeof window !== 'undefined' ? !!(window as any).requestIdleCallback : false;

      const idleCallbackSupported: boolean = idleCallbackSupportedW(ngWindow);
      let subscriptionDone: boolean = false;

      effect(() => {
        const authenticated = userStore.isAuthenticated();
        const superAdmin = userStore.isSuperAdmin();

        untracked(() => {
          if (authenticated && superAdmin) {
            if (isPlatformBrowser(platformId)) {
              if (!store.usersLoaded()) {
                subscriptionDone = false;

                applicationRef.isStable
                  .pipe(
                    takeWhile(() => !subscriptionDone),
                    filter(isStable => isStable),
                    tap(() => {
                      subscriptionDone = true;

                      if (idleCallbackSupported) {
                        ngWindow.requestIdleCallback(() => {
                          store.getUsers();

                          if (isDevMode()) {
                            console.log('%c 🗃️ Prefetching super admin users', 'background: #fff; color: #607D8B;');
                          }
                        });
                      } else {
                        store.getUsers();

                        if (isDevMode()) {
                          console.log('%c 🗃️ Prefetching super admin users', 'background: #fff; color: #607D8B;');
                        }
                      }
                    })
                  )
                  .subscribe();
              }
            }
          } else {
            store.resetUsers();
          }
        });
      });
    },
  })
);
