import {
  AfterViewInit,
  ApplicationRef,
  Directive,
  ElementRef,
  HostListener,
  inject,
  input,
  InputSignal,
  OnDestroy,
  OnInit,
  output,
  OutputEmitterRef,
  PLATFORM_ID,
} from '@angular/core';
import { isPlatformServer } from '@angular/common';
import { WINDOW } from '@owain/tokens/window.provider';
import { injectNetwork, NetworkState } from 'ngxtension/inject-network';
import { filter, takeWhile, tap } from 'rxjs/operators';
import { Subscription } from 'rxjs';

const observerSupported = (window: Window | null): boolean =>
  typeof window !== 'undefined' ? !!(window as any).IntersectionObserver : false;
const idleCallbackSupported = (window: Window | null): boolean =>
  typeof window !== 'undefined' ? !!(window as any).requestIdleCallback : false;

@Directive({
  selector: '[prefetch]',
})
export class PrefetchDirective implements OnInit, AfterViewInit, OnDestroy {
  public prefetchMode: InputSignal<('load' | 'hover' | 'visible')[]> = input<('load' | 'hover' | 'visible')[]>([
    'hover',
    'visible',
  ]);
  public refetchHover: InputSignal<boolean> = input<boolean>(false);

  public prefetch: OutputEmitterRef<void> = output<void>();

  private readonly applicationRef: ApplicationRef = inject(ApplicationRef);
  private readonly elementRef: ElementRef = inject(ElementRef);
  private readonly window: Window = inject(WINDOW);
  private readonly platformId: Object = inject(PLATFORM_ID);
  private readonly network: Readonly<NetworkState> = injectNetwork();

  private readonly observerSupported: boolean = observerSupported(this.window);
  private readonly idleCallbackSupported: boolean = idleCallbackSupported(this.window);

  private loaded: boolean = false;

  private idleCallbackId: number | undefined;
  private observer: IntersectionObserver | null | undefined;
  private subscriptions: Subscription[] = [];
  private subscriptionDone: boolean = false;

  ngOnInit(): void {
    if (isPlatformServer(this.platformId)) {
      return;
    }

    if (this.prefetchMode().includes('load')) {
      this.prefetchIdle('load');
    }
  }

  ngAfterViewInit(): void {
    if (isPlatformServer(this.platformId)) {
      return;
    }

    if (!this.prefetchMode().includes('visible')) {
      return;
    }

    if (!this.observerSupported) {
      this.prefetchIdle('visible');

      return;
    }

    this.observer = new IntersectionObserver((entries: IntersectionObserverEntry[]): void => {
      entries.forEach((entry: IntersectionObserverEntry): void => {
        if (entry.isIntersecting) {
          if (this.observer) {
            this.observer.disconnect();
          }

          this.prefetchIdle('visible');
        }
      });
    });

    if (!this.observer) {
      return;
    }

    this.observer.observe(this.elementRef.nativeElement);
  }

  ngOnDestroy(): void {
    this.subscriptionDone = true;
    this.subscriptions.forEach(subscription => subscription.unsubscribe());

    if (this.observer) {
      this.observer.disconnect();
    }

    if (this.idleCallbackId !== undefined) {
      this.window.cancelIdleCallback(this.idleCallbackId);
    }
  }

  public prefetchData(eventType: 'load' | 'hover' | 'visible'): void {
    if (this.loaded) {
      if (eventType === 'load' || eventType === 'visible' || (eventType === 'hover' && !this.refetchHover())) {
        return;
      }
    }

    this.loaded = true;

    if (this.network.supported()) {
      if (this.network.saveData() || (this.network.effectiveType() || '').includes('2g')) {
        return;
      }
    }

    if (eventType === 'hover' && this.refetchHover()) {
      this.subscriptionDone = false;
    }

    this.subscriptions.push(
      this.applicationRef.isStable
        .pipe(
          takeWhile((): boolean => !this.subscriptionDone),
          filter((isStable: boolean): boolean => isStable),
          tap((): void => {
            this.subscriptionDone = true;

            this.idleCallbackSupported
              ? (this.idleCallbackId = this.window.requestIdleCallback(() => {
                  this.prefetch.emit();
                }))
              : this.prefetch.emit();
          })
        )
        .subscribe()
    );
  }

  private prefetchIdle(eventType: 'load' | 'hover' | 'visible'): void {
    this.idleCallbackSupported
      ? (this.idleCallbackId = this.window.requestIdleCallback(() => {
          this.prefetchData(eventType);
        }))
      : this.prefetchData(eventType);
  }

  @HostListener('mouseenter')
  private onMouseEnter(): void {
    if (isPlatformServer(this.platformId)) {
      return;
    }

    if (this.prefetchMode().includes('hover')) {
      this.prefetchIdle('hover');
    }
  }
}
