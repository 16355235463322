import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import type { IBows } from '@archery-scoring/models/equipment.model';
import { BaseUrlService } from '../base-url.service';
import { CrudServiceInterface } from '@owain/store-features/models/crud-service.model';

@Injectable({
  providedIn: 'root',
})
export class BowsService implements CrudServiceInterface<IBows> {
  private readonly httpClient = inject(HttpClient);
  private readonly baseUrlService: BaseUrlService = inject(BaseUrlService);

  private url = `${this.baseUrlService.getBaseUrl()}/api/bows`;

  getEntities(): Observable<IBows[]> {
    return this.httpClient.get<IBows[]>(this.url);
  }

  getEntity(id: number): Observable<IBows> {
    return this.httpClient.get<IBows>(`${this.url}/${id}`);
  }

  addEntity(bow: IBows): Observable<IBows> {
    return this.httpClient.post<IBows>(this.url, {
      name: bow.name,
      activitytype: bow.activitytype,
      usetype: bow.usetype,
      poundage: bow.poundage,
    });
  }

  updateEntity(bow: IBows): Observable<IBows> {
    return this.httpClient.put<IBows>(`${this.url}/${bow.id}`, {
      name: bow.name,
      activitytype: bow.activitytype,
      usetype: bow.usetype,
      poundage: bow.poundage,
    });
  }

  deleteEntity(bow: IBows): Observable<IBows> {
    return this.httpClient.delete<IBows>(`${this.url}/${bow.id}`);
  }
}
