import { signalStore, type, withHooks } from '@ngrx/signals';
import type { IBows } from '@archery-scoring/models/equipment.model';
import { withEntities } from '@ngrx/signals/entities';
import { BowsService } from '../../services/http/bows.service';
import { ApplicationRef, effect, inject, isDevMode, PLATFORM_ID, untracked } from '@angular/core';
import { UserStore } from '../user/user.store';
import { isPlatformBrowser } from '@angular/common';
import { withCallState } from '@owain/store-features/features/call-state/call-state.feature';
import { withDataService } from '@owain/store-features/features/data-service/data-service-feature';
import { NotificationType } from '@owain/notifier/lib/models/notifications.model';
import { withSideEffects } from '@owain/store-features/features/side-effects/side-effects.feature';
import { withEntitiesLocalPagination } from '@owain/store-features/features/entities/pagination/entities-local-pagination';
import { withEntitiesSort } from '@owain/store-features/features/entities/sort/entities-sort';
import { onEvent, withEventHandler } from '@owain/store-features/features/event-handler/event-handler';
import { createEvent, props } from '@owain/store-features/features/event-handler/event-handler.util';
import { withDevtools } from '@owain/store-features/features/debugging/devtools';
import { WINDOW } from '@owain/tokens/window.provider';
import { filter, takeWhile, tap } from 'rxjs/operators';

export const EquipmentBowsStore = signalStore(
  { providedIn: 'root' },
  // withIndexedDB('equipment-store-state', ['callstate']),
  withDevtools('Archery Scoring', 'Equipment Bows'),
  withCallState({
    collection: 'bow',
  }),
  withEntities({
    entity: type<IBows>(),
    collection: 'bow',
  }),
  withEntitiesLocalPagination({
    entity: type<IBows>(),
    collection: 'bow',
    pageSize: 5,
  }),
  withEntitiesSort({
    entity: type<IBows>(),
    collection: 'bow',
    defaultSort: { field: 'id', direction: 'desc' },
  }),
  withDataService({
    loggerKey: 'EquipmentBowStore',
    dataServiceType: BowsService,
    collection: 'bow',
  }),
  withEventHandler(store => {
    const sideEffectEvent = createEvent(`bows.sideEffect`, props<{ key: string; status: string }>());

    return [
      onEvent(sideEffectEvent, ({ key, status }) => {
        if (key === 'add' || key === 'get' || key === 'update' || key === 'delete') {
          // @ts-ignore
          store.sortBowsEntities();
        }
      }),
    ];
  }),
  withSideEffects({
    collection: 'bow',
    add: {
      success: {
        type: NotificationType.SUCCESS,
        message: 'profile.newbowprofileadded',

        redirect: '/profile/bows',
      },
      failure: {
        type: NotificationType.ERROR,
        message: '500error',
      },
    },
    update: {
      success: {
        type: NotificationType.SUCCESS,
        message: 'profile.bowprofileupdated',

        redirect: '/profile/bows',
      },
      failure: {
        type: NotificationType.ERROR,
        message: '500error',
      },
    },
    delete: {
      success: {
        type: NotificationType.SUCCESS,
        message: 'profile.bowprofiledeleted',

        castEvent: 'ui:close-modal',
      },
      failure: {
        type: NotificationType.ERROR,
        message: '500error',
      },
    },
  }),
  withHooks({
    onInit(store) {
      const platformId: Object = inject(PLATFORM_ID);
      const ngWindow: Window = inject(WINDOW);
      const applicationRef: ApplicationRef = inject(ApplicationRef);
      const userStore: InstanceType<typeof UserStore> = inject(UserStore);

      const idleCallbackSupportedW = (window: Window | null): boolean =>
        typeof window !== 'undefined' ? !!(window as any).requestIdleCallback : false;

      const idleCallbackSupported: boolean = idleCallbackSupportedW(ngWindow);
      let subscriptionDone: boolean = false;

      effect(() => {
        const authenticated = userStore.isAuthenticated();
        untracked(() => {
          if (authenticated) {
            if (isPlatformBrowser(platformId)) {
              if (!store.bowsLoaded()) {
                subscriptionDone = false;

                applicationRef.isStable
                  .pipe(
                    takeWhile(() => !subscriptionDone),
                    filter(isStable => isStable),
                    tap(() => {
                      subscriptionDone = true;

                      if (idleCallbackSupported) {
                        ngWindow.requestIdleCallback(() => {
                          store.getBows();

                          if (isDevMode()) {
                            console.log('%c 🗃️ Prefetching user bows', 'background: #fff; color: #607D8B;');
                          }
                        });
                      } else {
                        store.getBows();

                        if (isDevMode()) {
                          console.log('%c 🗃️ Prefetching user bows', 'background: #fff; color: #607D8B;');
                        }
                      }
                    })
                  )
                  .subscribe();
              }
            }
          } else {
            store.resetBows();
          }
        });
      });
    },
  })
);
