import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { BaseUrlService } from '../base-url.service';
import type { ISuperAdminOrganization } from '@archery-scoring/models/organization.model';
import { CrudServiceInterface } from '@owain/store-features/models/crud-service.model';

@Injectable({
  providedIn: 'root',
})
export class SuperAdminOrganizationService implements CrudServiceInterface<ISuperAdminOrganization> {
  private readonly httpClient = inject(HttpClient);
  private readonly baseUrlService: BaseUrlService = inject(BaseUrlService);

  private url = `${this.baseUrlService.getBaseUrl()}/api/organizations`;

  getEntities(): Observable<ISuperAdminOrganization[]> {
    return this.httpClient.get<ISuperAdminOrganization[]>(this.url);
  }

  getEntity(id: number): Observable<ISuperAdminOrganization> {
    // Not implemented
    return of(<ISuperAdminOrganization>{});
  }

  addEntity(organization: ISuperAdminOrganization): Observable<ISuperAdminOrganization> {
    return this.httpClient.post<ISuperAdminOrganization>(this.url, { name: organization.name, url: organization.url });
  }

  updateEntity(organization: ISuperAdminOrganization): Observable<ISuperAdminOrganization> {
    return this.httpClient.put<ISuperAdminOrganization>(`${this.url}/${organization.id}`, {
      name: organization.name,
      url: organization.url,
    });
  }

  deleteEntity(organization: ISuperAdminOrganization): Observable<ISuperAdminOrganization> {
    return this.httpClient.delete<ISuperAdminOrganization>(`${this.url}/${organization.id}`);
  }
}
