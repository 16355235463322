import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import type { IArrows } from '@archery-scoring/models/equipment.model';
import { BaseUrlService } from '../base-url.service';
import { map } from 'rxjs/operators';
import { CrudServiceInterface } from '@owain/store-features/models/crud-service.model';

@Injectable({
  providedIn: 'root',
})
export class ArrowsService implements CrudServiceInterface<IArrows> {
  private readonly httpClient: HttpClient = inject(HttpClient);
  private readonly baseUrlService: BaseUrlService = inject(BaseUrlService);

  private url = `${this.baseUrlService.getBaseUrl()}/api/arrows`;

  getEntities(): Observable<IArrows[]> {
    return this.httpClient.get<IArrows[]>(this.url).pipe(
      map((arrows: IArrows[]): {
          length: number | null;
          userId?: number;
          name: string;
          activitytype: number;
          usetype: number;
          spine: number | null;
          weight: number | null;
          id: number;
        }[] =>
        arrows.map((arrow: IArrows): {
          length: number | null;
          userId?: number;
          name: string;
          activitytype: number;
          usetype: number;
          spine: number | null;
          weight: number | null;
          id: number;
        } => {
          return {
            ...arrow,
            length: arrow.length ? arrow.length / 100 : arrow.length,
          };
        })
      )
    );
  }

  getEntity(id: number): Observable<IArrows> {
    return this.httpClient.get<IArrows>(`${this.url}/${id}`);
  }

  addEntity(arrow: IArrows): Observable<IArrows> {
    return this.httpClient
      .post<IArrows>(this.url, {
        name: arrow.name,
        activitytype: arrow.activitytype,
        usetype: arrow.usetype,
        spine: arrow.spine,
        length: arrow.length ? arrow.length * 100 : arrow.length,
        weight: arrow.weight,
      })
      .pipe(
        map(arrow => {
          return {
            ...arrow,
            length: arrow.length ? arrow.length / 100 : arrow.length,
          };
        })
      );
  }

  updateEntity(arrow: IArrows): Observable<IArrows> {
    return this.httpClient
      .put<IArrows>(`${this.url}/${arrow.id}`, {
        name: arrow.name,
        activitytype: arrow.activitytype,
        usetype: arrow.usetype,
        spine: arrow.spine,
        length: arrow.length ? arrow.length * 100 : arrow.length,
        weight: arrow.weight,
      })
      .pipe(
        map(arrow => {
          return {
            ...arrow,
            length: arrow.length ? arrow.length / 100 : arrow.length,
          };
        })
      );
  }

  deleteEntity(arrow: IArrows): Observable<IArrows> {
    return this.httpClient.delete<IArrows>(`${this.url}/${arrow.id}`);
  }
}
