import { signalStore, type, withHooks } from '@ngrx/signals';
import { withEntities } from '@ngrx/signals/entities';
import type { ISuperAdminOrganization } from '@archery-scoring/models/organization.model';
import { SuperAdminOrganizationService } from '../../services/http/superadmin-organization.service';
import { ApplicationRef, effect, inject, isDevMode, PLATFORM_ID, untracked } from '@angular/core';
import { UserStore } from '../user/user.store';
import { isPlatformBrowser } from '@angular/common';
import { withCallState } from '@owain/store-features/features/call-state/call-state.feature';
import { withDataService } from '@owain/store-features/features/data-service/data-service-feature';
import { NotificationType } from '@owain/notifier/lib/models/notifications.model';
import { withSideEffects } from '@owain/store-features/features/side-effects/side-effects.feature';
import { withEntitiesFilter } from '@owain/store-features/features/entities/filter/entites-filter.feature';
import { withEntitiesLocalPagination } from '@owain/store-features/features/entities/pagination/entities-local-pagination';
import { withDevtools } from '@owain/store-features/features/debugging/devtools';
import { WINDOW } from '@owain/tokens/window.provider';
import { filter, takeWhile, tap } from 'rxjs/operators';

export const SuperAdminOrganizationsStore = signalStore(
  { providedIn: 'root' },
  withDevtools('Archery Scoring', 'Super Admin Organizations'),
  withCallState({
    collection: 'organization',
  }),
  withEntities({
    entity: type<ISuperAdminOrganization>(),
    collection: 'organization',
  }),
  withEntitiesFilter({
    entity: type<ISuperAdminOrganization>(),
    collection: 'organization',
    defaultFilter: { search: '' },
    filterFn: (entity, filter) =>
      !filter?.search ||
      !!(entity?.name && entity?.name.toLowerCase().includes(filter?.search.toLowerCase())) ||
      !!(entity?.url && entity?.url.toLowerCase().includes(filter?.search.toLowerCase())),
  }),
  withEntitiesLocalPagination({
    entity: type<ISuperAdminOrganization>(),
    collection: 'organization',
    pageSize: 10,
  }),
  withDataService({
    loggerKey: 'SuperAdminOrganizationsStore',
    key: 'superadmin-organizations',
    dataServiceType: SuperAdminOrganizationService,
    collection: 'organization',
  }),
  withSideEffects({
    key: 'superadmin-organizations',
    add: {
      success: {
        type: NotificationType.SUCCESS,
        message: 'superadmin.organizationcreated',

        redirect: '/superadmin/organizations',
      },
      failure: {
        type: NotificationType.ERROR,
        message: '500error',
      },
    },
    update: {
      success: {
        type: NotificationType.SUCCESS,
        message: 'superadmin.organizationedited',

        redirect: '/superadmin/organizations',
      },
      failure: {
        type: NotificationType.ERROR,
        message: '500error',
      },
    },
    delete: {
      success: {
        type: NotificationType.SUCCESS,
        message: 'superadmin.organizationdeleted',

        castEvent: 'ui:close-modal',
      },
      failure: {
        type: NotificationType.ERROR,
        message: '500error',
      },
    },
  }),
  withHooks({
    onInit(store) {
      const platformId: Object = inject(PLATFORM_ID);
      const ngWindow: Window = inject(WINDOW);
      const applicationRef: ApplicationRef = inject(ApplicationRef);
      const userStore: InstanceType<typeof UserStore> = inject(UserStore);

      const idleCallbackSupportedW = (window: Window | null): boolean =>
        typeof window !== 'undefined' ? !!(window as any).requestIdleCallback : false;

      const idleCallbackSupported: boolean = idleCallbackSupportedW(ngWindow);
      let subscriptionDone: boolean = false;

      effect(() => {
        const authenticated = userStore.isAuthenticated();
        const superAdmin = userStore.isSuperAdmin();

        untracked(() => {
          if (authenticated && superAdmin) {
            if (isPlatformBrowser(platformId)) {
              if (!store.organizationsLoaded()) {
                subscriptionDone = false;

                applicationRef.isStable
                  .pipe(
                    takeWhile(() => !subscriptionDone),
                    filter(isStable => isStable),
                    tap(() => {
                      subscriptionDone = true;

                      if (idleCallbackSupported) {
                        ngWindow.requestIdleCallback(() => {
                          store.getOrganizations();

                          if (isDevMode()) {
                            console.log(
                              '%c 🗃️ Prefetching super admin organizations',
                              'background: #fff; color: #607D8B;'
                            );
                          }
                        });
                      } else {
                        store.getOrganizations();

                        if (isDevMode()) {
                          console.log(
                            '%c 🗃️ Prefetching super admin organizations',
                            'background: #fff; color: #607D8B;'
                          );
                        }
                      }
                    })
                  )
                  .subscribe();
              }
            }
          } else {
            store.resetOrganizations();
          }
        });
      });
    },
  })
);
