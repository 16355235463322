import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { BaseUrlService } from '../base-url.service';
import type { IUser, IUserConnectInner } from '@archery-scoring/models/user.model';
import { CrudServiceInterface } from '@owain/store-features/models/crud-service.model';

@Injectable({
  providedIn: 'root',
})
export class SuperAdminUserService implements CrudServiceInterface<IUser> {
  private readonly httpClient = inject(HttpClient);
  private readonly baseUrlService: BaseUrlService = inject(BaseUrlService);

  private url = `${this.baseUrlService.getBaseUrl()}/api/user`;

  getEntities(): Observable<IUser[]> {
    return this.httpClient.get<IUser[]>(this.url);
  }

  getEntity(id: number): Observable<IUser> {
    // Not implemented
    return of(<IUser>{});
  }

  addEntity(user: IUser): Observable<IUser> {
    // Not implemented
    return of(<IUser>{});
  }

  updateEntity(user: IUser): Observable<IUser> {
    // Not implemented
    return of(<IUser>{});
  }

  deleteEntity(user: IUser): Observable<IUser> {
    return this.httpClient.delete<IUser>(`${this.url}/${user.id}`);
  }

  userAssignOrganizations(id: number, connect: IUserConnectInner[]): Observable<IUser> {
    const url = `${this.url}/assign-organizations/${id}`;
    return this.httpClient.put<IUser>(url, { connect });
  }
}
